var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "4" } },
            [
              _c(
                "base-material-chart-card",
                {
                  staticClass: "px-4 py-3",
                  attrs: {
                    data: _vm.dailySalesChart.data,
                    options: _vm.dailySalesChart.options,
                    color: "success",
                    type: "Line"
                  }
                },
                [
                  _c(
                    "h4",
                    { staticClass: "display-1 font-weight-light mt-2" },
                    [_vm._v("\n          Rounded Line Chart\n        ")]
                  ),
                  _c("div", { staticClass: "grey--text font-weight-light" }, [
                    _vm._v("\n          Line Chart\n        ")
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "4" } },
            [
              _c(
                "base-material-chart-card",
                {
                  staticClass: "px-4 py-3",
                  attrs: {
                    data: _vm.emailsSubscriptionChart.data,
                    options: _vm.emailsSubscriptionChart.options,
                    "responsive-options":
                      _vm.emailsSubscriptionChart.responsiveOptions,
                    color: "orange darken-1",
                    type: "Line"
                  }
                },
                [
                  _c(
                    "h4",
                    { staticClass: "display-1 font-weight-light mt-2" },
                    [_vm._v("\n          Line Chart With Points\n        ")]
                  ),
                  _c("div", { staticClass: "grey--text font-weight-light" }, [
                    _vm._v("\n          Straight Lines Chart\n        ")
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "4" } },
            [
              _c(
                "base-material-chart-card",
                {
                  staticClass: "px-4 py-3",
                  attrs: {
                    data: _vm.dataCompletedTasksChart.data,
                    options: _vm.dataCompletedTasksChart.options,
                    color: "info",
                    type: "Bar"
                  }
                },
                [
                  _c(
                    "h4",
                    { staticClass: "display-1 font-weight-light mt-2" },
                    [_vm._v("\n          Simple Bar Chart\n        ")]
                  ),
                  _c("div", { staticClass: "grey--text font-weight-light" }, [
                    _vm._v(
                      "\n          Last Last Campaign Performance\n        "
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "base-material-card",
                {
                  staticClass: "px-4 py-3",
                  attrs: {
                    id: "coloured-line",
                    color: "info",
                    icon: "mdi-chart-timeline-variant"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "after-heading",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "display-1 mt-2 font-weight-light" },
                            [
                              _vm._v(
                                "\n            Coloured Line Chart\n            "
                              ),
                              _c("span", { staticClass: "subtitle-1" }, [
                                _vm._v("— Rounded")
                              ])
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("chartist", {
                    staticClass: "mt-3",
                    staticStyle: { "max-height": "150px" },
                    attrs: {
                      data: _vm.colouredLine.data,
                      options: _vm.colouredLine.options,
                      type: "Line"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "py-3" }),
              _c(
                "base-material-card",
                {
                  staticClass: "px-4 py-3",
                  attrs: {
                    id: "coloured-line",
                    color: "warning",
                    icon: "mdi-chart-timeline-variant"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "after-heading",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "display-1 font-weight-light mt-2" },
                            [
                              _vm._v(
                                "\n            Coloured Line Chart\n            "
                              ),
                              _c("span", { staticClass: "subtitle-1" }, [
                                _vm._v("— Multiple")
                              ])
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("chartist", {
                    staticClass: "mt-3",
                    staticStyle: { "max-height": "150px" },
                    attrs: {
                      data: _vm.multipleLine.data,
                      options: _vm.multipleLine.options,
                      type: "Line"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "6" } },
            [
              _c(
                "base-material-card",
                {
                  staticClass: "px-4 py-3",
                  attrs: {
                    id: "multiple-bar",
                    color: "success",
                    icon: "mdi-poll-box"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "after-heading",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "display-1 mt-2 font-weight-light" },
                            [
                              _vm._v(
                                "\n            Multiple Bars Chart\n            "
                              ),
                              _c("span", { staticClass: "subtitle-1" }, [
                                _vm._v("— Bar Chart")
                              ])
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c("chartist", {
                    staticClass: "mt-3",
                    staticStyle: { "max-height": "150px" },
                    attrs: {
                      data: _vm.multipleBar.data,
                      options: _vm.multipleBar.options,
                      type: "Bar"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "py-3" }),
              _c(
                "base-material-card",
                {
                  staticClass: "px-4 py-3",
                  attrs: {
                    id: "pie",
                    color: "success",
                    icon: "mdi-chart-pie",
                    title: "Pie Chart"
                  }
                },
                [
                  _c("chartist", {
                    staticStyle: { "max-height": "250px" },
                    attrs: {
                      data: _vm.pie.data,
                      options: _vm.pie.options,
                      type: "Pie"
                    }
                  }),
                  _c("v-divider", { staticClass: "ma-3" }),
                  _c(
                    "div",
                    { staticClass: "px-3" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "body-2 text-uppercase grey--text font-weight-bold mb-3"
                        },
                        [_vm._v("\n            Legend\n          ")]
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0", attrs: { align: "center" } },
                        [
                          _c("v-avatar", {
                            staticClass: "mr-1",
                            attrs: { color: "info", size: "12" }
                          }),
                          _c(
                            "span",
                            { staticClass: "mr-3 font-weight-light" },
                            [_vm._v("Apple")]
                          ),
                          _c("v-avatar", {
                            staticClass: "mr-1",
                            attrs: { color: "warning", size: "12" }
                          }),
                          _c(
                            "span",
                            { staticClass: "mr-3 font-weight-light" },
                            [_vm._v("Samsung")]
                          ),
                          _c("v-avatar", {
                            staticClass: "mr-1",
                            attrs: { color: "red", size: "12" }
                          }),
                          _c(
                            "span",
                            { staticClass: "mr-3 font-weight-light" },
                            [_vm._v("Windows Phone")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }